import React from 'react'
import Select from '@cloversites/react-select'
import classNames from 'classnames'

export default function SelectCheckMultiple(props) {
  return (
    <Select
      multi
      commaSeparatedValues
      closeOnSelect={false}
      removeSelected={false}
      optionRenderer={(option) => {
        // Surround the label in a span so it can be vertically aligned with
        // respect to a checkbox to its left.
        return <span>{option.label}</span>
      }}
      {...props}
      className={classNames('check-multiple', props.className)}
    />
  )
}
