import { assign, bindAll, map, omit, reduce } from 'lodash'
import React from 'react'
import SelectCheckMultiple from '../../../lib/components/SelectCheckMultiple'

function selectOptions(...options) {
  return map(options, (x) => ({ label: x, value: x }))
}

function mapValues(options) {
  return map(options, (option) => option.value)
}

export default class SmallGroupsFilter extends React.Component {

  constructor(props) {
    super(props)

    bindAll(this, 'handleDayChange', 'handleTimeChange', 'handleZipChange')

    this.state = {
      selections: {
        properties: {},
        day: null,
        time: null,
        zip: null
      }
    }
  }

  selects() {
    return reduce(this.props.filters, (selects, value, key) => {
      if (key === 'properties') {
        return [...selects, ...this.propertySelects(value)]
      } else if (key === 'day' && value) {
        return [...selects, this.daySelect()]
      } else if (key === 'time' && value) {
        return [...selects, this.timeSelect()]
      }
      return selects
    }, [])
  }

  handleFilterChange() {
    if (this.props.onChange) {
      this.props.onChange(this.state.selections)
    }
  }

  setSelection(key, value) {
    this.setState({selections: assign({}, this.state.selections, {[key]: value})}, this.handleFilterChange)
  }

  setSelectedProperty(key, value) {
    if (value.length) this.setSelection('properties', assign({}, this.state.selections.properties, {[key]: value}))
    else this.setSelection('properties', omit(this.state.selections.properties, key))
  }

  handlePropertyChange(propertyName, e) {
    this.setSelectedProperty(propertyName, mapValues(e))
  }

  handleDayChange(e) {
    this.setSelection('day', mapValues(e))
  }

  handleTimeChange(e) {
    this.setSelection('time', mapValues(e))
  }

  handleZipChange(e) {
    this.setSelection('zip', e.target.value ? e.target.value : null)
  }

  propertySelects(properties) {
    return map(properties, (values, propertyName) =>
      <SelectCheckMultiple
        key={`property.${propertyName}`}
        placeholder={propertyName}
        options={selectOptions(...values)}
        onChange={(...args) => this.handlePropertyChange(propertyName, ...args)}
        value={this.state.selections.properties[propertyName]} />
    )
  }

  daySelect() {
    return (
      <SelectCheckMultiple
        key="day"
        placeholder="Day"
        options={selectOptions(
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        )}
        onChange={this.handleDayChange}
        value={this.state.selections.day} />
    )
  }

  timeSelect() {
    return (
      <SelectCheckMultiple
        key="time"
        placeholder="Time"
        options={selectOptions(
          'Morning',
          'Afternoon',
          'Evening'
        )}
        onChange={this.handleTimeChange}
        value={this.state.selections.time} />
    )
  }

  zipInput() {
    const zip = this.props.filters.zip
    if (!zip) return
    return (
      <div className="sites-input-group">
        <input className="sites-input sites-input-with-right-addon"
               placeholder="Zip Code"
               onChange={this.handleZipChange} />
        <span className="sites-input-group-addon icon-location-stroke" />
      </div>
    )
  }

  render() {
    return (
      <div className="small-groups-filter">
        {this.selects()}
        {this.zipInput()}
      </div>
    )
  }

}
