import { assign, forEach, keys } from 'lodash'
import ReactDOM from 'react-dom'
import ReactOnRails from 'react-on-rails'
import createReactElement from 'react-on-rails/node_package/lib/createReactElement'
import isRouterResult from 'react-on-rails/node_package/lib/isCreateReactElementResultNonReactComponent'

// Disable linting for the following code since most of it was copied straight
// from another package and we want to leave it alone as much as possible.
/* eslint-disable */

// Copied from react-on-rails@11.0.10; no changes.
function findContext() {
  if (typeof window.ReactOnRails !== 'undefined') {
    return window;
  } else if (typeof ReactOnRails !== 'undefined') {
    return global;
  }

  throw new Error(`\
ReactOnRails is undefined in both global and window namespaces.
  `);
}

// Copied from react-on-rails@11.0.10; no changes.
function parseRailsContext() {
  const el = document.getElementById('js-react-on-rails-context');
  if (el) {
    return JSON.parse(el.textContent);
  }

  return null;
}

// Copied from react-on-rails@11.0.10; no changes.
function delegateToRenderer(componentObj, props, railsContext, domNodeId, trace) {
  const { name, component, isRenderer } = componentObj;

  if (isRenderer) {
    if (trace) {
      console.log(`\
DELEGATING TO RENDERER ${name} for dom node with id: ${domNodeId} with props, railsContext:`,
      props, railsContext);
    }

    component(props, railsContext, domNodeId);
    return true;
  }

  return false;
}

// Copied from react-on-rails@11.0.10; no changes.
function domNodeIdForEl(el) {
  return el.getAttribute('data-dom-id');
}

// Copied from react-on-rails@11.0.10; added argument to set additional props.
/**
 * Used for client rendering by ReactOnRails. Either calls ReactDOM.hydrate, ReactDOM.render, or
 * delegates to a renderer registered by the user.
 * @param el
 */
function render(el, railsContext, additionalProps) {
  const context = findContext();
  // This must match lib/react_on_rails/helper.rb
  const name = el.getAttribute('data-component-name');
  const domNodeId = domNodeIdForEl(el);
  const props = assign(JSON.parse(el.textContent), additionalProps);
  const trace = el.getAttribute('data-trace');

  try {
    const domNode = document.getElementById(domNodeId);
    if (domNode) {
      const componentObj = context.ReactOnRails.getComponent(name);
      if (delegateToRenderer(componentObj, props, railsContext, domNodeId, trace)) {
        return;
      }

      // Hydrate if available and was server rendered
      const shouldHydrate = !!ReactDOM.hydrate && !!domNode.innerHTML;

      const reactElementOrRouterResult = createReactElement({
        componentObj,
        props,
        domNodeId,
        trace,
        railsContext,
        shouldHydrate,
      });

      if (isRouterResult(reactElementOrRouterResult)) {
        throw new Error(`\
You returned a server side type of react-router error: ${JSON.stringify(reactElementOrRouterResult)}
You should return a React.Component always for the client side entry point.`);
      } else if (shouldHydrate) {
        ReactDOM.hydrate(reactElementOrRouterResult, domNode);
      } else {
        ReactDOM.render(reactElementOrRouterResult, domNode);
      }
    }
  } catch (e) {
    e.message = `ReactOnRails encountered an error while rendering component: ${name}.\n` +
      `Original message: ${e.message}`;
    throw e;
  }
}

/* eslint-enable */

const register = ReactOnRails.register

assign(ReactOnRails, {

  // Advise this method to fix a bug in the ReactOnRails API.
  register(components) {
    register.apply(this, arguments)
    forEach(keys(components), (component) => {
      // Fix bug where function components calling hooks throw errors:
      // https://github.com/shakacode/react_on_rails/issues/1198
      // TODO: Get this fixed in the react-on-rails package instead.
      ReactOnRails.getComponent(component).generatorFunction = false
    })
  },

  // Extend the ReactOnRails API with methods geared towards our architecture.

  // Like ReactOnRails.reactOnRailsPageLoaded, except render/hydrate a single
  // component, and optionally pass extra props to it.  This allows us to bridge
  // non-React code to React components, passing callbacks to components, etc.
  initializeComponent(el, props) {
    const railsContext = parseRailsContext()
    render(el, railsContext, props)
  },

  // Companion method to #initializeComponent to unmount a component with a
  // similar interface.
  deinitializeComponent(el) {
    const domNodeId = domNodeIdForEl(el)
    const domNode = document.getElementById(domNodeId)
    if (domNode) {
      ReactDOM.unmountComponentAtNode(domNode)
    }
  }

})
